.container{
    position :"fixed";
    width : "100%";
    height :"100vh";
}

.info{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    flex-basis: 1;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 2rem;
}